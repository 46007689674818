import moment from 'moment'
import { DateTimePicker } from '../src/rad_common_js/dateTimePicker';

export class Patients {
  static setup() {
    if ($(".patient_communication_text_reply").length) {
      if ($("#patient_communication_text_reply").val() === "0") {
        $("#patient_communication_text_reply").closest('.patient_communication_text_reply').next().removeClass("d-none");
      }

      $(".patient_communication_text_reply select").change(function() {
        if ($(this).val() === "0") {
          $(this).closest('.patient_communication_text_reply').next().removeClass("d-none");
          return $(this).closest('.patient_communication_text_reply').next().find('textarea').html("");
        } else {
          $(this).closest('.patient_communication_text_reply').next().addClass("d-none");
          return $(this).closest('.patient_communication_text_reply').next().find('textarea').html($(this).find('option:selected').text());
        }
      });
    }

    if ($('#patient-communications').length > 0) {
      setInterval((function() {
        $.getJSON('/patients/maybe_render_communications' + window.location.search, {
          last_communication_id: $('#patient-communications').data('last-communication-id'),
          patient_id: $('#patient-communications').data('patient-id')
        }, function(data){
          if (data.new_message) {
            $('#patient-communications').replaceWith(data.html);
          }
        });
      }), 60000);
    }

    if ($("#confirmation_confirmed_by_customer_at").length) {
      const today = moment().format('M/D/YYYY h:mm A');
      return document.getElementById('confirmation_confirmed_by_customer_at').value = today;
    }

    const $autoRefillForm = $('form.patient-auto-refills');
    $autoRefillForm.on('submit', function(e) {
      if ($('#patient_consent_provided').val() != '') {
        return null;
      }

      if (!$autoRefillForm.find('#patient_auto_refills').prop('checked')) {
        return null;
      }

      e.preventDefault();
      $.ajax({
        url: $autoRefillForm.data('consent-url'),
        type: 'GET',
        data: $autoRefillForm.serialize(),
        success(data) {
          if (data.message) {
            $('#patient_consent_provided').val(false);
            $autoRefillForm.submit();
          }
        }
      });
    });

    Patients.setupPatientMatchModal();
    Patients.setupPatientVerificationForm();
  }

  static setupPatientMatchModal() {
    const $matchPatientModal = $('#match-patient-modal');
    if ($matchPatientModal.length == 0 || $('form .alert.alert-danger').length > 0) {
      return false;
    }

    const patientId = $('#patient_patient_registration_attributes_unmatched_patient_id').val();
    const showMatchPatientModal = $('#patient_registration_form').length == 0 || patientId > 0;
    const $matchPatientForm = $matchPatientModal.find('#patient_match_form');

    if ($('.landing-page').length > 0) {
      $('.landing-page .continue').on('click', event => {
        event.preventDefault();
        if (showMatchPatientModal) {
          $matchPatientModal.modal('show');
        } else {
          $('.patient-matchable').removeClass('d-none');
        }
        $('.landing-page').toggle(false);
      });
    } else if (showMatchPatientModal) {
      $matchPatientModal.modal('show');
    }

    $matchPatientModal.on('hide.bs.modal', function () {
      Patients.setupPatientContactInfoForm();

      if ($('#patient-contact-info').length == 0 || $('#patient-contact-info').is(':hidden')) {
        $('.patient-matchable').removeClass('d-none');
      }
    });

    if ($('.patient-match-js').length > 0) {
      $matchPatientForm.on('submit', function(event) {
        $matchPatientModal.find('.alert').toggle(false).remove();
        event.preventDefault();

        if (!Patients.patientMatchFormValidations($matchPatientForm)) {
          return false;
        }

        $.ajax({
          type: 'POST',
          url: $matchPatientForm.attr('action'),
          data: {
            id: $matchPatientForm.find('#patient_encoded_id').val(),
            patient: {
              birth_date: $matchPatientForm.find('#patient_birth_date').val(),
              email: $matchPatientForm.find('#patient_email').val() || '',
              phone_number: $matchPatientForm.find('#patient_phone_number').val() || ''
            }
          },
          success(data) {
            if (data.prescriptions && data.prescriptions.length > 0) {
              $('input.one-time-fill-consent').siblings('label').html(data.prescriptions);
            }

            $matchPatientModal.modal('hide');
          },
          error(data) {
            $matchPatientForm.prepend(`
              <div class="alert in alert-danger" style="display: none">
                ${data && data.responseJSON && data.responseJSON.message ? data.responseJSON.message : 'Failed to find patient, please try again.'}
              </div>
            `);
            $matchPatientModal.find('.alert').toggle(400);
            $matchPatientForm.find('input[type="submit"]').prop('disabled', false);
          }
        });
      });
    }
  }

  static patientMatchFormValidations(form) {
    const language = $('html').prop('lang');
    const $matchPatientModal = $('#match-patient-modal');
    const email = form.find('#patient_email').val();
    const phoneNumber = form.find('#patient_phone_number').val();
    let birthDate = form.find('#patient_birth_date').val();
    let errors = [];

    form.find('input[type="submit"]').prop('disabled', true);

    if (birthDate.length > 0) {
      DateTimePicker.convertDateTimeInputs();
      birthDate = form.find('#patient_birth_date').val();
    } else {
      if (language == 'es') {
        errors.push('Por favor, introduzca una fecha de nacimiento válida');
      } else {
        errors.push('Please enter a valid birth date');
      }
    }

    if (email != undefined && phoneNumber != undefined && phoneNumber.length == 0 && email.length == 0) {
      if (language == 'es') {
        errors.push('Ingrese su número de teléfono o correo electrónico');
      } else {
        errors.push('Please enter your phone number or email address');
      }
    } else if (phoneNumber != undefined && phoneNumber.length == 0) {
      if (language == 'es') {
        errors.push('Ingrese su número de teléfono');
      } else {
        errors.push('Please enter your phone number');
      }
    } else if (email != undefined && email.length == 0) {
      if (language == 'es') {
        errors.push('Ingrese su correo electrónico');
      } else {
        errors.push('Please enter your email address');
      }
    }

    if (errors.length > 0) {
      $matchPatientModal.find('.modal-body').find('.alert').remove();
      $matchPatientModal.find('.modal-body').prepend(`
        <div class="alert in alert-danger">
          <ul class="pl-2 pl-sm-3">
            ${errors.map(error => '<li>' + error + '</li>').join('')}
          </ul>
        </div>
      `);
      form.find('input[type="submit"]').prop('disabled', false);
      return false;
    }

    return true;
  }


  static setupPatientVerificationForm() {
    const $matchPatientForm = $('#patient-verification #patient_match_form');
    $matchPatientForm.on('submit', function (event) {
      $matchPatientForm.find('.alert').toggle(200);
      event.preventDefault();

      if (!Patients.patientMatchFormValidations($matchPatientForm)) {
        return false;
      }

      $.ajax({
        type: 'POST',
        url: $matchPatientForm.attr('action'),
        data: {
          patient: {
            encoded_id: $matchPatientForm.find('#patient_encoded_id').val(),
            birth_date: $matchPatientForm.find('#patient_birth_date').val(),
            email: $matchPatientForm.find('#patient_email').val(),
            phone_number: $matchPatientForm.find('#patient_phone_number').val(),
          },
          verify_contact_info: true
        },
        error(data) {
          $matchPatientForm.find('.alert').remove();
          $matchPatientForm.prepend(`
            <div class="alert in alert-danger" style="display: none">
              ${data && data.responseJSON && data.responseJSON.message ? data.responseJSON.message : 'Failed to find patient, please try again.'}
            </div>
          `);
          $matchPatientForm.find('.alert').toggle(400);
          $matchPatientForm.find('input[type="submit"]').prop('disabled', false);
        }
      });
    });
  }

  static setupPatientContactInfoForm() {
    if ($('#patient-contact-info').is(':visible')) {
      $('form.verify-submit-form').on('submit', function(event) {
        event.preventDefault();

        $.ajax({
          type: 'PUT',
          url: event.currentTarget.action,
          success() {
            $('#patient-contact-info').toggle(false).remove();
            $('.patient-matchable').removeClass('d-none');
          }
        });
      });
    }
  }
}
