export class PatientClaims {
  constructor() {
    this.$form = $('form#patient_claim_form');
  }

  static setup() {
    let patientClaims = new PatientClaims();
    patientClaims.setup();
  }

  setup() {
    if (this.$form.length > 0) {
      return this.setupPatientClaimForm();
    }
  }

  setupPatientClaimForm() {
    let patientClaims = this;
    let $injuryType = this.$form.find('input[name="patient_claim[injury_type]"]');

    patientClaims.toggleInjuryTypeInputs();
    $injuryType.on('change', () => patientClaims.toggleInjuryTypeInputs());
  }

  toggleInjuryTypeInputs() {
    let $injuryType = this.$form.find('input[name="patient_claim[injury_type]"]:checked');
    if ($injuryType.length == 0) {
      return false;
    }

    switch($injuryType.val()) {
      case 'workers_comp': {
        $('.patient_claim_insurance_carrier_name label').text('Workers Comp Insurance Carrier Name');
        $('.patient_claim_insurance_carrier_phone_number label').text('Workers Comp Insurance Carrier Phone Number');
        $('#employer-row:hidden, #insurance-carrier-row:hidden, #attorney-row:hidden').toggle(200);
        break;
      }
      case 'auto_injury': {
        $('.patient_claim_insurance_carrier_name label').text('Auto Insurance Carrier Name');
        $('.patient_claim_insurance_carrier_phone_number label').text('Auto Insurance Carrier Phone Number');
        $('#insurance-carrier-row:hidden, #attorney-row:hidden').toggle(200);
        $('#employer-row:visible').toggle(200);
        break;
      }
      case 'personal_injury': {
        $('#attorney-row:hidden').toggle(200);
        $('#insurance-carrier-row:visible, #employer-row:visible').toggle(200);
        break;
      }
    }
  }
}
