require('raphael/raphael.min');
require('morris.js/morris.min');
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min.js');

import '../src/bootstrap_and_overrides.scss';
import '../src/css/branding.scss';
import '../src/css/helpers.scss';
import 'morris.js/morris.css';
import '../src/css/prescription_requests.scss';
import '../src/css/signature.scss';
import '../src/css/ledger.scss';
import '../src/css/patients.scss';
import '../src/css/portal/home.scss';
import '../src/css/portal/refills.scss';
import '../src/css/portal/users.scss';
import 'fullcalendar/dist/fullcalendar.min.css';

import $ from 'jquery';
window.$ = $;
$.fn.reverse = [].reverse;

import { PusherSetup } from '../src/pusher_setup';
import { AttachmentObjects } from '../src/attachment_objects';
import { Helpers } from '../src/helpers';
import { ConsentModal } from '../src/consent_modal';
import { DynamicNotifications } from '../src/dynamic_notifications';
import { DynamicSelectable } from '../src/dynamic_selectable';
import { PrescriberEvents } from '../src/prescriber_events';
import { PatientCommunications } from '../src/patient_communications';
import { ForYourRecordsFax } from '../src/for_your_records_fax';
import { GeoLocator } from '../src/geoLocator';
import { Orders } from '../src/orders';
import { PatientBatchCommunications } from '../src/patient_batch_communications';
import { Patients } from '../src/patients';
import { Payments } from '../src/payments';
import { PrescriptionRequestProducts } from '../src/prescription_request_products';
import { Prescriptions } from '../src/prescriptions';
import { PrescriptionRequests } from '../src/prescription_requests';
import { Signature } from '../src/signature';
import { ProductIngredients } from '../src/product_ingredients';
import { ProductQuantities } from '../src/product_quantities';
import { Refills } from '../src/refills';
import { StoredPaymentMethod } from '../src/stored_payment_methods';
import { UserNotifications } from '../src/user_notifications';
import { TwoFactorAuthenticationSetupModal } from '../src/two_factor_authentication_setup_modal';
import { PatientClaims } from '../src/patient_claims';
import { PatientConsultations } from '../src/patient_consultations';
import { PatientRegistrations } from '../src/patient_registrations';
import { PatientReviews } from '../src/patient_reviews';
import { ProviderRelations } from '../src/provider_relations';
import { AddressVerificationModal } from '../src/address_verification_modal';
import { BillingQueue } from '../src/billing_queue';
import { BarcodeScanner } from '../src/barcode_scanner';
import { ScanForm } from '../src/scan_form';

$(document).ready(function() {
  PusherSetup.setup();
  AttachmentObjects.setup();
  Helpers.setup();
  ConsentModal.setup();
  DynamicNotifications.setup();
  DynamicSelectable.setup();
  PrescriberEvents.setup();
  PatientCommunications.setup();
  ForYourRecordsFax.setup();
  GeoLocator.setup();
  Orders.setup();
  PatientBatchCommunications.setup();
  Patients.setup();
  Payments.setup();
  PrescriptionRequestProducts.setup();
  PrescriptionRequests.setup();
  Signature.setup();
  ProductIngredients.setup();
  ProductQuantities.setup();
  Refills.setup();
  StoredPaymentMethod.setup();
  UserNotifications.setup();
  Prescriptions.setup();
  TwoFactorAuthenticationSetupModal.setup();
  PatientClaims.setup();
  PatientRegistrations.setup();
  PatientReviews.setup();
  PatientConsultations.setup();
  ProviderRelations.setup();
  AddressVerificationModal.setup();
  BillingQueue.setup();
  BarcodeScanner.setup();
  let scanForm = new ScanForm();
  scanForm.setup();
})
