import { Orders } from '../src/orders';

export class BillingQueue {
  static setup() {
    if ($('#billing-queue').length > 0) {
      return this.setupBillingQueue();
    }
  }

  static setupBillingQueue() {
    $('#bulk-assign').tooltip('disable');

    $('#assign-all-users').click(function() {
      const $btn = $(this);
      $btn.toggleClass('selected');

      if ($btn.hasClass('selected')) {
        $btn.text('Unselect All');
        $('input[name="assign_user[]"]').prop('checked', true);
      } else {
        $btn.text('Select All');
        $('input[name="assign_user[]"]').prop('checked', false);
      }
    });

    $('#bulk-assign').hover(
      function() {
        if($('input[name="assign_user[]"]:checked').length == 0) {
          $(this).tooltip('enable');
          $(this).tooltip('show');
        } else {
          $(this).tooltip('disable');
          $(this).tooltip('hide');
        }
      },
      function() {
        $(this).tooltip('hide');
      }
    );

    $('#bulk-assign').click(function() {
      if($('input[name="assign_user[]"]:checked').length > 0) {
        const $modal = $('#bulk-assign-modal');
        $modal.modal('show');

        $('#search_order_ids').val(
          $('input[name="assign_user[]"]:checked').map(function() { return $(this).val(); }).toArray()
        );
      }
    });

    $('button.add-note').click(function() {
      const $modal = $('#quick-note-modal');
      const $btn = $(this);

      $modal.find('.order-title').empty();
      $btn.closest('tr').find('.order-link').clone().prepend('Order# ').appendTo('#quick-note-modal .order-title');
      $btn.closest('tr').find('.patient-link').clone().addClass('ml-3').appendTo('#quick-note-modal .order-title');
      $modal.find('input#order_id').val($btn.data('order-id'));
      $modal.find('.alert').remove();
      $modal.modal('show');
    });

    $('#quick-note-form form').on('submit', function(e) {
      e.preventDefault();

      const $modal = $('#quick-note-modal');
      const $form = $(this);
      const order_id = $form.find('#order_id').val();
      let note = $('#order_note').val();

      $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: { order: { id: order_id, note: note } },
        success(data) {
          $modal.modal('hide');
        },
        error(data) {
          $form.find('.alert').remove();
          $form.prepend(`
            <div class="alert in alert-danger">
              <button type="button" class="close" data-dismiss="alert">×</button>
              ${data && data.responseJSON && data.responseJSON.message ? data.responseJSON.message : 'Failed to add note, please try again.'}
            </div>
          `);
        }
      });
    });

    $('button.process-now').click(function() {
      const $modal = $('#process-now-modal');
      const $btn = $(this);
      const $row = $btn.closest('tr');
      const billed = $row.find('td.billed :first-child').attr('aria-label') == 'true';

      $modal.find('.order-title').empty();
      $row.find('.order-link').clone().prepend('Order# ').appendTo('#process-now-modal .order-title');
      $row.find('.patient-link').clone().addClass('ml-3').appendTo('#process-now-modal .order-title');
      $modal.find('#order_current_activity').val($row.find('.activity-link').text());
      $modal.find('input#order_id').val($btn.data('order-id'));
      $modal.find('.alert').remove();
      if (billed) {
        $modal.find('.order_billed label').text('Mark order as Unbilled/Reversed?');
      } else {
        $modal.find('.order_billed label').text('Mark order as Billed?');
      }

      $modal.modal('show');
    });

    const $processNowForm = $('#process-now-form form');
    Orders.setupActivitySelect($processNowForm);
    $processNowForm.on('submit', function(e) {
      e.preventDefault();

      const $modal = $('#process-now-modal');
      const $form = $(this);
      const order_id = $form.find('#order_id').val();
      let activity_id = $form.find('#order_activity_id').val();
      const billed = $form.find('#order_billed').is(':checked') ? 1 : 0;

      $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: {
          order: {
            id: order_id,
            activity_id: activity_id,
            billed: billed,
            basket_number: $form.find('#order_basket_number').val(),
            tag_number: $form.find('#order_tag_number').val()
          }
        },

        success(data) {
          $modal.modal('hide');
          window.location.href = window.location.href;
        },
        error(data) {
          $form.find('.alert').remove();
          $form.prepend(`
            <div class="alert in alert-danger">
              <button type="button" class="close" data-dismiss="alert">×</button>
              ${data && data.responseJSON && data.responseJSON.message ? data.responseJSON.message : 'Failed to process order, please try again.'}
            </div>
          `);
        }
      });
    });

    $('input[name="order[copay]"], input[name="order[basket_number]"], select[name="order[treatment_type]"], input[name="order[prescription_numbers][]"]').on('input', function() {
      $(this).closest('form').find('input[type=submit]').toggle(true);
    });

    $('.order_prescription_numbers button.array-add-btn').on('click', function() {
      $(this).closest('form').find('input[type=submit]').toggle(true);
    });

    $('.edit_order').on('ajax:success', function() {
      const $form = $(this);
      const $copay = $form.find('input[name="order[copay]"]');

      $form.find('input[type=submit]').toggle(false);

      if ($copay.length > 0 && parseFloat($copay.val()) >= 0) {
        $copay.val(parseFloat($copay.val()).toFixed(2));
      }
    });
  }
}
