export class ProviderRelations {
    static setup() {
        if ($('#provider-relations-report').length == 0) {
            return;
        }

        $('#bulk-assign').tooltip('disable');

        $('#assign-all-users').click(function() {
            const $btn = $(this);
            $btn.toggleClass('selected');

            if ($btn.hasClass('selected')) {
                $btn.text('Unselect All');
                $('input[name="assign_user[]"]').prop('checked', true);
            } else {
                $btn.text('Select All');
                $('input[name="assign_user[]"]').prop('checked', false);
            }
        });

        $('#bulk-assign').hover(
            function() {
                if($('input[name="assign_user[]"]:checked').length == 0) {
                    $(this).tooltip('enable');
                    $(this).tooltip('show');
                } else {
                    $(this).tooltip('disable');
                    $(this).tooltip('hide');
                }
            },
            function() {
                $(this).tooltip('hide');
            }
        );

        $('#bulk-assign').click(function() {
            if($('input[name="assign_user[]"]:checked').length > 0) {
                const $modal = $('#bulk-assign-modal');
                $modal.modal('show');

                $('#search_prescription_request_ids').val(
                    $('input[name="assign_user[]"]:checked').map(function() { return $(this).val(); }).toArray()
                );
            }
        });

        $('button.add-note').click(function() {
            const $modal = $('#quick-note-modal');
            const $btn = $(this);

            $modal.find('.prescription-request-title').empty();
            $btn.closest('tr').find('.prescription-request-link').clone().appendTo('#quick-note-modal .prescription-request-title');
            $modal.find('input#prescription_request_id').val($btn.data('prescription-request-id'));
            $modal.find('.alert').remove();
            $modal.modal('show');
        });

        $('#prescription_request_note').on('change', function() {
            const $modal = $('#quick-note-modal');
            const $note = $(this);

            $modal.find('#other-note').toggleClass('d-none', $note.val() !== 'Other');
        });

        $('#quick-note-form form').on('submit', function(e) {
            e.preventDefault();

            const $modal = $('#quick-note-modal');
            const $form = $(this);
            const prescription_request_id = $form.find('#prescription_request_id').val();

            let note = $('#prescription_request_note').val();
            if (note == 'Other') {
                note = $form.find('#prescription_request_other_note').val();
            }

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: { prescription_request: { id: prescription_request_id, note: note } },
                success(data) {
                    $('#flash-messages').html(`
                        <div class="alert in alert-success">
                            <button type="button" class="close" data-dismiss="alert">×</button>
                            ${data.message}
                        </div>
                    `);
                    $(`button.add-note[data-prescription-request-id=${prescription_request_id}]`).closest('tr').remove();
                    const counter = Number($('#provider-relations-report.card .card-header .title-text .count').text()) - 1;
                    $('#provider-relations-report.card .card-header .title-text .count').text(counter);
                    $modal.modal('hide');
                },
                error(data) {
                    $form.find('.alert').remove();
                    $form.prepend(`
                        <div class="alert in alert-danger">
                            <button type="button" class="close" data-dismiss="alert">×</button>
                            ${data && data.responseJSON && data.responseJSON.message ? data.responseJSON.message : 'Failed to add note, please try again.'}
                        </div>
                    `);
                }
            });
        });
    }
}
