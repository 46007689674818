export class PatientConsultations {
    static setup() {
        if ($('#patient-consultations-index').length > 0) {
            return this.setupPatientConsultationsIndex();
        }
    }

    static setupPatientConsultationsIndex() {
        $('#bulk-assign').tooltip('disable');

        $('#assign-all-users').click(function() {
            const $btn = $(this);
            $btn.toggleClass('selected');

            if ($btn.hasClass('selected')) {
                $btn.text('Unselect All');
                $('input[name="assign_user[]"]').prop('checked', true);
            } else {
                $btn.text('Select All');
                $('input[name="assign_user[]"]').prop('checked', false);
            }
        });

        $('#bulk-assign').hover(
            function() {
                if($('input[name="assign_user[]"]:checked').length == 0) {
                    $(this).tooltip('enable');
                    $(this).tooltip('show');
                } else {
                    $(this).tooltip('disable');
                    $(this).tooltip('hide');
                }
            },
            function() {
                $(this).tooltip('hide');
            }
        );

        $('#bulk-assign').click(function() {
            if($('input[name="assign_user[]"]:checked').length > 0) {
                const $modal = $('#bulk-assign-modal');
                $modal.modal('show');

                $('#search_patient_consultation_ids').val(
                    $('input[name="assign_user[]"]:checked').map(function() { return $(this).val(); }).toArray()
                );
            }
        });

        $('button.add-note').click(function() {
            const $modal = $('#quick-note-modal');
            const $btn = $(this);

            $modal.find('.patient-title').empty();
            $btn.closest('tr').find('.patient-link').clone().appendTo('#quick-note-modal .patient-title');
            $modal.find('input#patient_id').val($btn.data('patient-id'));
            $modal.find('.alert').remove();
            $modal.modal('show');
        });

        $('#patient_note').on('change', function() {
            const $modal = $('#quick-note-modal');
            const $note = $(this);

            $modal.find('#other-note').toggleClass('d-none', $note.val() !== 'Other');
        });

        $('#quick-note-form form').on('submit', function(e) {
            e.preventDefault();

            const $modal = $('#quick-note-modal');
            const $form = $(this);
            const patient_id = $form.find('#patient_id').val();

            let note = $('#patient_note').val();
            if (note == 'Other') {
                note = $form.find('#patient_other_note').val();
            }

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: { patient: { id: patient_id, note: note } },
                success() {
                    $modal.modal('hide');
                },
                error(data) {
                    $form.find('.alert').remove();
                    $form.prepend(`
                        <div class="alert in alert-danger">
                            <button type="button" class="close" data-dismiss="alert">×</button>
                            ${data && data.responseJSON && data.responseJSON.message ? data.responseJSON.message : 'Failed to add note, please try again.'}
                        </div>
                    `);
                }
            });
        });
    }
}
